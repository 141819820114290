/**************************************************/
/*Control bar*/
/**************************************************/
.hide{
    display: none;
}

.disable {
    pointer-events:none;
    display: none;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

:-webkit-full-screen {
    width: 100%;
    top:0;
    z-index: -100;
    background: none;
}

:-ms-fullscreen {
    width: auto;
    height: auto;
    margin: auto;
}

.time-display,
.duration-display{
    padding:11px;
    color: white;
    font-weight: normal;
    font-size: .9em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.time-display{
    float:left;
}

.duration-display{
    float: right;
}

.live-icon {
    cursor: pointer;
}
.live-icon.live{
    color:red !important;
    pointer-events: none;
    cursor: default;
}

.control-button{
    float: left;
    padding:1rem 4rem;
    cursor: pointer;
}

.settings-button{
    float: left;
    padding:0.75rem;
    cursor: pointer;
}

.back-button{
    float: left;
    padding:0.7rem;
    cursor: pointer;
    background-color: #232323;
    border-radius: 8px;
    line-height: 1;
}

.player-info-box{
    float: left;
    padding: 1rem;
    background-color: #232323;
    border-radius: 8px;
    margin-left: 1rem;
    /*line-height: 1;*/
}

.control-icon-layout
 {
    float: right;
    padding:9px 10px;
    cursor: pointer;
}

.btn-fullscreen {
    margin-right: 10px;
}

.volumebar {
    float: right;
    width: 70px;
}

/* .video-controller {
    min-height:35px;
    z-index: 2147483646;
} */

.video-controller-fullscreen {
    position: fixed;
    z-index:2147483647;
    width: 100%;
    bottom: 0;
    left: 0;
}

/* .menu,
.video-controller {
    background-color: black;
} */
.menu {
    background-color: #232323;
    /*padding: 20px;*/
    border-radius: 8px;
    padding-bottom: 8px;
    z-index: 2;
}

.menu-item-unselected,
.menu-item-selected{
    font-weight: normal;
    font-size: .9em;
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}

.menu-item-unselected {
    /*color: white;*/
    /*background-color: rgba(20, 20, 20, 0.6);*/
}

.menu-item-over,
.menu-item-selected {
    background-color: #343434;
    /*color: black;*/
}

.menu-sub-menu-title {
    /*background-color: #191919;*/
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    /*font-weight: bold;*/
    /*font-size: 1.0em;*/
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}

.menu-item-selected {
    /*opacity: .7;*/
}

.menu-item-selected:before {
    content: url('../img/checked-icon.svg');
    position: absolute;
    left: 23px;
}

.menu ul{
    list-style-type: none;
    padding:0;
    margin:0;
    border-top: 1px solid #8F8F8F;
}

.menu li{
    padding: 8px 23px 8px 64px;
    cursor: pointer;
    /*margin: 10px;*/
}

.menu {
    position: absolute;
}

#bitrateMenu .menu-sub-menu-title {
    min-width: 150px;
}

@font-face {
    font-family: 'icomoon';
    src: url("fonts/icomoon.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/*.icon-play,*/
/*.icon-pause,*/
/*.icon-caption,*/
/*.icon-mute-off,*/
/*.icon-mute-on,*/
/*.icon-fullscreen-enter,*/
/*.icon-fullscreen-exit,*/
/*.icon-tracks,*/
/*.icon-bitrate {*/
/*    font-family: 'icomoon';*/
/*    font-size: 20px;*/
/*    color: white;*/
/*    text-shadow: none;*/
/*    -webkit-font-smoothing: antialiased;*/
/*}*/

/*.icon-fullscreen-enter:before {*/
/*    content: "\e90b";*/
/*}*/
/*.icon-fullscreen-exit:before {*/
/*    content: "\e90c";*/
/*}*/

/* .icon-play:before {
    content: "\e910";
    background: "../img/play-button.svg";
} */

.icon-play {
	background-image: url(../img/play-button.svg);
	width: 34px;
	height: 37px;
	display: block;
	background-size: cover;
}

.icon-pause:before {
    content: "\e911";
}
.icon-mute-on:before {
    content: "\e909";
}
.icon-mute-off:before {
    content: "\e918";
}
.icon-caption:before {
    content: "\e901";
}
.icon-bitrate:before {
    content: "\e905";
}
.icon-tracks:before {
    content: "\e90a";
}

.seekContainer {
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

input[type="range"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    height: 14px;
    border: none;
    margin:12px 5px;
    padding: 1px 2px;
    border-radius: 5px;
    background: #232528;
    box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
    -webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
    outline: none; /* no focus outline */
}

input[type=range]::-moz-focus-outer {
    border: 0;
}

input[type="range"]::-moz-range-track {
    border: inherit;
    background: transparent;
}

input[type="range"]::-ms-track {
    border: inherit;
    color: transparent; /* don't drawn vertical reference line */
    background: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: transparent;
}

input[type="range"]::-ms-tooltip {
    display: none;
}

/* thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 8px;
    border: none;
    border-radius: 2px;
    background-color:rgb(0, 150, 215);
}
input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 8px;
    border: none;
    border-radius: 2px;
    background-color:rgb(0, 150, 215);
}

input[type="range"]::-ms-thumb {
    width: 15px;
    height: 8px;
    border: none;
    border-radius: 2px;
    background-color:rgb(0, 150, 215);
}

.thumbnail-container {
    position: absolute;
    text-align: center;
    z-index: 100;
}

.thumbnail-elem {
    position: relative;
    box-shadow: 0px 0px 0.9em #000000;
    transform-origin: center bottom;
}

.thumbnail-time-label {
    position: relative;
    bottom: 1.8em;
    display: table;
    margin: 0 auto;
    padding: 2px 5px 2px 5px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*.play .seekbar-complete {*/
/*}*/

.seekbar-complete {
    overflow: visible;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    height: 5px;
    background: #000000;
    position: relative;
    /*overflow: hidden;*/
}

.seekbar-buffer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 5px;
    /* background: lightgray; */
    background: gray;
    border-radius: 4px;
}

.seekbar-live {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 5px;
    background-color: white;
    border-radius: 4px;
	/*border-right: white;*/
	/*border-right-width: 7px;*/
	/*border-right-style: solid;*/
}

.seekbar-play {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 5px;
    /* background: rgb(0, 150, 215); */
    background: #62E479;
    border-radius: 4px;
}

.play .seekbar-complete .seekbar-play::after {
    content: "";
    height: 13px;
    width: 13px;
    background: #62E479;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: -5px;
    bottom: 50%;
    transform: translate(0, 50%);
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 150ms ease !important;
    -moz-transition: all 150ms ease !important;
    -o-transition: all 150ms ease !important;
    transition: all 150ms ease !important;
}

.play .seekbar-complete:hover .seekbar-play::after {
    opacity: 1;
}

/*** custom ***/
video {
    width: 100%;
    /* height: 100%; */
    /* height: calc(100% - 45px); */
}

.dash-video-player {
    position: relative;
    height:100vh;
/*  width: 640px; */
    margin: 0 auto;
    background-color: gray;
    padding: 10px;
    text-align: center;
}

.videoContainer {
    width: 100%;
    /* height: 100vh;
    width: max-content;
    display: block;
    margin-left: auto;
    margin-right: auto;			 */
}		

.video-controller {
	/* position: relative;
	top: -156px; */
	position: fixed;
	bottom: 0;
    min-height: 96px;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    width: 100%;
    z-index: 1;
}

.seekbar-play:before {
	position: absolute;
	top: -0.333333333333333em;
	right: -0.5em;
	font-size: 0.9em;
}

.seekbar-play {
	content: "";
}