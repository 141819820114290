input:focus {
    outline: none;
    border: none;
}

.button:hover {
    background: rgba(255, 255, 255, 0.12);
    /*background-color: #0aa131;*/
}

.second-button {
    background-color: #484848;
}

.second-button:hover {
    background-color: #423f3f;
}

.cgates-logo {
    position: absolute;
    width: 117px;
    height: 30px;
    left: 68px;
    top: 30px;
}

.unauthorised {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-dropdown {
    position: absolute;
    top: 30px;
    right: 68px;
    width: 50px;
    z-index: 1;
}

.language-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
}

.language-dropdown .dropdown-toggle > p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    margin: 0;
}

.language-dropdown .dropdown-toggle:hover ~ .dropdown-content {
    visibility: visible;
}

.language-dropdown .dropdown-content {
    padding-top: 20px;
    visibility: hidden;
    background-color: #1F1F1F;
}

.language-dropdown .dropdown-content:hover {
    visibility: visible;
}

.language-dropdown .dropdown-content > button {
    display: block;
    background: none;
    color: #FFFFFF;
    border: none;
    padding: 2px 0;
    margin: auto;
    width: 30px;
    text-align: left;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
}

.language-dropdown .dropdown-content > button:focus {
    outline: none;
}

.language-dropdown .dropdown-content > button:hover {
    color: #20B149;
}

@media (min-width: 375px) {
    .sign-up-form {
        width: 312px;
        padding-top: 100px;
    }

    .sign-up-form .button {
        margin-top: 9px;
    }
}

@media (min-width: 768px) {
    .sign-up-form {
        width: 634px;
        padding-top: 100px;
    }

    .sign-up-form .button {
        margin-top: 21px;
    }
}

@media (min-width: 1366px) {
    .sign-up-form {
        padding-top: 125px;
    }

    /*.sign-up-form .button {*/
    /*    margin-top: 106px;*/
    /*}*/
}

.sign-up-form {
    margin: auto;
}

.sign-up-form .button {
    text-transform: uppercase;
}

.sign-up-form .text-fields .text-field {
    position: relative;
    margin-bottom: 20px;
}

.sign-up-form .text-fields .text-field .error-message,
.new-password-form .text-fields .text-field .error-message,
.password-recovery-form .text-field .error-message,
.account-activation-form .text-field .error-message {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #EA2121;
    margin: 5px 0 0 30px;
    padding: 0 3px;
}

.sign-up-form .text-fields .text-field > label,
.password-recovery-form .text-field > label,
.new-password-form .text-fields .text-field > label,
.account-activation-form .text-field > label {
    position: absolute;
    top: -7px;
    display: block;
    margin: 0 0 0 30px;
    padding: 0 3px;
    background-color: #1F1F1F;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7e7878;
}

.sign-up-form .text-fields .text-field > input,
.sign-up-form .text-fields .text-field > select,
.sign-up-form .text-fields .text-field .react-tel-input > input,
.password-recovery-form .text-field > input,
.new-password-form .text-fields .text-field > input,
.account-activation-form .text-field > input {
    width: 100%;
    height: 59px;
    background-color: #1F1F1F;
    color: #FFFFFF;
    border: 2px solid #808080;
    border-radius: 30px;
    padding-left: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-family: Montserrat, sans-serif;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.sign-up-form .text-fields .text-field > input:-webkit-autofill,
.sign-up-form .text-fields .text-field .react-tel-input > input:-webkit-autofill,
.password-recovery-form .text-field > input:-webkit-autofill,
.new-password-form .text-fields .text-field > input:-webkit-autofill,
.account-activation-form .text-field > input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #1F1F1F inset;
    -webkit-text-fill-color: #FFFFFF;
}

.sign-up-form .text-fields .text-field > input:focus,
.sign-up-form .text-fields .text-field > select:focus,
.sign-up-form .text-fields .text-field .react-tel-input > input:focus {
    outline: none;
    border: 2px solid #20B149;
}

.sign-up-form .text-fields .text-field .react-tel-input:focus-within ~ label {
    color: #20B149;
}

.sign-up-form .text-fields .text-field > input:focus + label,
.sign-up-form .text-fields .text-field > select:focus + label
{
    color: #20B149;
}

.sign-up-form .text-fields .text-field .react-tel-input .flag-dropdown {
    width: 30px;
    background-color: #1F1F1F;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 2px solid #808080;
    border-right: 0;
}

.sign-up-form .text-fields .text-field .react-tel-input > input:focus ~ .flag-dropdown {
    border-color: #20B149;
}

.sign-up-form .text-fields .text-field .react-tel-input .flag-dropdown .country-list {
    background-color: #1F1F1F;
    left: -1px;
}

.sign-up-form .text-fields .text-field .react-tel-input .flag-dropdown .country-list > li {
    background-color: #1F1F1F;
}

.sign-up-form .text-fields .text-field .react-tel-input .flag-dropdown .country-list > li:hover {
    background-color: #20B149;
}

.sign-up-form .text-fields .text-field .react-tel-input .flag-dropdown .selected-flag {
    padding: 0 0 0 2px;
    width: 30px;
    background: none;
}

@media (min-width: 375px) {
    .registering-page {
        width: 312px;
    }

    .registering-page .text {
        width: 312px;
    }
}

@media (min-width: 768px) {
    .registering-page {
        width: 634px;
    }

    .registering-page .text {
        width: 375px;
    }
}

.registering-page {
    margin: auto;
    padding-top: 120px;
}

.registering-page .button {
    margin-top: 40px;
    text-transform: uppercase;
}

.registering-page .title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;
}

.registering-page .text {
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    margin: 0 auto 20px auto;
}

@media (min-width: 375px) {
    .conditions-page {
        width: 352px;
        padding-top: 100px;
    }

    .conditions-page .buttons-container .button {
        width: 312px;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .conditions-page {
        width: 745px;
        padding-top: 125px;
    }

    .conditions-page .buttons-container .button {
        width: 634px;
        margin-top: 75px;
    }
}

/*@media (min-width: 1366px) {*/
/*    .conditions-page {*/
/*        padding-top: 290px;*/
/*    }*/
/*}*/

.conditions-page {
    margin: auto;
}

.conditions-page hr {
    border: 1px solid #4D4D4D;
}

.conditions-page .buttons-container {
    display: flex;
    justify-content: center;
}

.conditions-page .buttons-container .button {
    text-transform: uppercase;
}

.conditions-page .conditions-container {
    display: flex;
    align-items: center;
    position: relative;
}

.conditions-page .conditions-container > p {
    display: inline-block;
    margin-left: 30px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding-right: 35px;
}

.conditions-page .conditions-container > button {
    position: absolute;
    right: 0;
    width: 40px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
}

.conditions-page .conditions-container > button:focus {
    outline: none;
}

@media (min-width: 375px) {
    .conditions-content-page {
        width: 336px;
        padding-top: 60px;
    }
}

@media (min-width: 768px) {
    .conditions-content-page {
        width: 712px;
    }
}

@media (min-width: 1366px) {
    .conditions-content-page {
        width: 1000px;
        padding-top: 0;
    }
}

.conditions-content-page {;
    margin: auto;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    text-align: left;
}

.conditions-content-page p {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 30px;
}

.conditions-content-page ol {
    padding-left: 0;
}

.conditions-content-page a {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
}

.conditions-content-page .button {
    margin-bottom: 50px;
    text-transform: uppercase;
}

.conditions-content-page .close-button {
    background: none;
    border: none;
    position: absolute;
    right: 30px;
    top: 125px;
}

.conditions-content-page .close-button:focus {
    outline: none;
}

.conditions-content-page > p > label {
    outline: none;
}

@media (min-width: 375px) {
    .status-page {
        width: 336px;
        padding-top: 90px;
    }

    .status-page .buttons-container {
        width: 312px;
    }

    .status-page .status {
        margin-bottom: 20px;
    }

    .status-page .message {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) {
    .status-page {
        width: 726px;
    }

    .status-page .buttons-container {
        width: 634px;
    }

    .status-page .status {
        margin-bottom: 3em;
    }

    .status-page .message {
        margin-bottom: 40px;
    }
}

@media (min-width: 1366px) {
    .status-page {
        padding-top: 270px;
    }
}

.status-page {
    margin: auto;
}

.status-page .status > p {
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;
    text-align: center;
}

.status-page .message {
    text-align: center;
}

.status-page .message > p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
}

.status-page .buttons-container {
    margin: auto;
}

.status-page .buttons-container .button {
    margin-top: 20px;
    text-transform: uppercase;
}

.status-page .buttons-container .second-button {
    background-color: #484848;
}

@media (min-width: 375px) {
    .password-recovery-form {
        padding-top: 90px;
    }

    .password-recovery-form .button {
        width: 312px;
    }

    .password-recovery-form .text-field {
        width: 312px;
        margin: 0 auto 20px auto;
    }

    .password-recovery-form > h1 {
        margin-bottom: 80px;
    }
}

@media (min-width: 768px) {
    .password-recovery-form .button {
        width: 634px;
    }

    .password-recovery-form .text-field {
        width: 634px;
        margin: 0 auto 150px auto;
    }

    .password-recovery-form > h1 {
        margin-bottom: 50px;
    }
}

@media (min-width: 1366px) {
    .password-recovery-form {
        padding-top: 200px;
    }
}

.password-recovery-form > h1 {
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;
    display: flex;
    justify-content: center;
}

.password-recovery-form .text-field {
    position: relative;
}

.password-recovery-form .text-field > input:focus {
    outline: none;
    border: 2px solid #20B149;
}

.password-recovery-form .text-field > input:focus + label {
    color: #20B149;
}

.password-recovery-form .button {
    display: flex;
    justify-content: center;
    margin: auto;
}

@media (min-width: 375px) {
    .new-password-form {
        padding-top: 90px;
    }

    .new-password-form .text-fields {
        width: 312px;
        margin: 0 auto;
    }

    .new-password-form .button {
        width: 312px;
    }
}

@media (min-width: 768px) {
    .new-password-form .text-fields {
        width: 634px;
        margin: 0 auto 70px auto;
    }

    .new-password-form .button {
        width: 634px;
    }
}

@media (min-width: 1366px) {
    .new-password-form {
        padding-top: 120px;
    }
}

.new-password-form > h1 {
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.new-password-form .text-fields .text-field {
    position: relative;
    margin-bottom: 20px;
}

.new-password-form .text-fields .text-field > input:focus {
    outline: none;
    border: 2px solid #20B149;
}

.new-password-form .text-fields .text-field > input:focus + label {
    color: #20B149;
}

.new-password-form .button {
    margin: auto;
    display: flex;
    justify-content: center;
}

@media (min-width: 375px) {
    .account-activation-form {
        padding-top: 90px;
    }

    .account-activation-form .text-field {
        width: 312px;
        margin: 0 auto 20px auto;
    }

    .account-activation-form .button {
        width: 312px;
    }
}

@media (min-width: 768px) {
    .account-activation-form .text-field {
        width: 634px;
        margin: 0 auto 100px auto;
    }

    .account-activation-form .button {
        width: 634px;
    }
}

@media (min-width: 1366px) {
    .account-activation-form {
        padding-top: 120px;
    }
}

.account-activation-form > h1 {
    font-weight: bold;
    font-size: 37px;
    line-height: 45px;
    text-align: center;
    margin-bottom: 25px;
}

.account-activation-form > p {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    width: 375px;
    text-align: center;
    margin: 0 auto 25px auto;
}

.account-activation-form .text-field {
    position: relative;
}

.account-activation-form .text-field > input:focus {
    outline: none;
    border: 2px solid #20B149;
}

.account-activation-form .text-field > input:focus + label {
    color: #20B149;
}

.account-activation-form .button {
    margin: auto;
    display: flex;
    justify-content: center;
}