.pageWrapper
    display: flex
    flex-direction: column
    align-items: center
    background-color: #232323
    padding: 0 24px
    color: #fff
    min-height: 100%
    overflow: scroll

.navWrapper
    display: flex
    justify-content: space-between
    align-items: center
    min-height: 60px
    margin-bottom: 20px
    width: 100%

.title
    font-size: 34px
    font-weight: 500
    line-height: 36px
    text-align: center
    margin-bottom: 15px

.subText
    font-size: 18px
    font-weight: 400
    line-height: 26px
    text-align: center
    margin-bottom: 25px
    @media (min-width: 581px)
        max-width: 400px

.linkButton
    display: flex
    align-items: center
    justify-content: center
    border-radius: 20px
    background-color: #34A241
    color: #fff
    text-align: center
    min-height: 40px
    margin-bottom: 60px
    width: 100%
    @media (min-width: 581px)
        max-width: 340px
        margin-bottom: 70px
    @media (min-width: 901px)
        margin-bottom: 100px

.bottomText
    font-size: 16px
    font-weight: 400
    line-height: 24px
    text-align: center
    margin-bottom: 20px
    color: #B1B1B1
    @media (min-width: 581px)
        max-width: 400px
        margin-bottom: 30px

.storeWrapper
    display: grid
    grid-template-columns: repeat(2, 140px)
    justify-items: center
    grid-gap: 15px
    padding-bottom: 40px
    @media (min-width: 581px)
        grid-template-columns: repeat(3, 140px)
